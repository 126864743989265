import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import {getLocal, removeLocal} from "@/utils/cache";

const routes = [
    {
        path: '/',
        name: 'index',
        meta:{
            title: '首页',
            login: true
        },
        component: () => import('@/views/index')
    },
    {
        path: '/:tagId',
        name: 'index-type',
        meta:{
            title: '首页',
            login: true
        },
        component: () => import('@/views/index')
    },
    {
        path: '/admin',
        name: 'admin',
        meta:{
            title: '加载后台',
            login: true
        },
        component: () => import('@/views/admin/index'),
        children:[
            {
                path: '/admin/upload',
                name: 'upload',
                meta:{
                    title: '上传资源',
                    login: true
                },
                component: () => import('@/views/admin/upload'),
            },
            {
                path: '/admin/list',
                name: 'list',
                meta:{
                    title: '资源列表',
                    login: true
                },
                component: () => import('@/views/admin/list'),
            },
            {
                path: '/admin/tag',
                name: 'tag',
                meta:{
                    title: '标签管理',
                    login: true
                },
                component: () => import('@/views/admin/tag'),
            },
            {
                path: '/admin/comment',
                name: 'comment',
                meta: {
                    title: '留言板',
                    login: true
                },
                component: () => import('@/views/admin/comment.vue')
            },
            {
                path: '/admin/setting',
                name: 'setting',
                meta:{
                    title: '其他设置',
                    login: true
                },
                component: () => import('@/views/admin/setting'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta:{
            title: '登录'
        },
        component: () => import('@/views/auth/login')
    },
    {
        path: '/:catchAll(.*)',
        meta:{
            title: '404'
        },
        component: () => import('@/views/other/404')
    }
]


const router = createRouter({
    history: createWebHistory(),  //hash
    routes
})

router.beforeEach(function (to,from,next){
    if(to.meta.title == null){
        document.title = to.params.key + ' - Wall';
    }else{
        document.title = to.meta.title + ' - Wall';
    }

    if (to.meta.login) {
        // 若需要登录, 检查下当前是否登录
        if (getLocal("user") != null && getLocal("user") != "") {
            // 已经登录, 正常访问
            next()
        } else {
            // 携带当前url, 跳转到登录页面
            router.replace({
                path: '/login',
                query: {
                    redirect: to.fullPath // 登录成功后跳入当前路径
                }
            })
            next()
        }
    }

    // 当登录且访问的时候admin时，默认跳转到上传文件菜单
    if(to.path.indexOf("admin") != -1){
        if(getLocal("user") != null && getLocal("user") != "") {
            // 判断默认是不是通过浏览器直接输入/admin，如果是自动重定向到上传资源页面
            if(to.path == '/admin'){
                next(to.path+"/upload")
            }
        }
    }
    next()

})

router.afterEach(function() {})

export default router
