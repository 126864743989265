<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'App',
    components: {},
    data(){
      return {

      }
    },
  }

  /*var _hmt=_hmt||[];(function(){var hm=document.createElement("script");hm.src="https://hm.baidu.com/hm.js?5ab7c6f79ab1fce14c4a8231fd40166c";var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(hm,s)})();*/
</script>
<script></script>

<style>
</style>
